
<body>
  <div class="pnf-container">
      <div class="bird">
        <img style="z-index:999; width:40%;height:40%;" src="./../../assets/pageNotFound.png"/>
        <div class="text-container">
          <div class="goback">
            <a routerLink="/"> GO BACK TO WEBABY</a>
          </div>
        </div>
        <!-- <div class="btn btn-primary btn-round">Error 404</div> -->
      </div>
  </div>
  <div class="waveWrapper waveAnimation">
    <div class="waveWrapperInner bgTop">
      <div class="wave waveTop" style="background-image: url('http://front-end-noobs.com/jecko/img/wave-top.png')"></div>
    </div>
    <div class="waveWrapperInner bgMiddle">
      <div class="wave waveMiddle" style="background-image: url('http://front-end-noobs.com/jecko/img/wave-mid.png')"></div>
    </div>
    <div class="waveWrapperInner bgBottom">
      <div class="wave waveBottom" style="background-image: url('http://front-end-noobs.com/jecko/img/wave-bot.png')"></div>
    </div>
  </div>
</body>