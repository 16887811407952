<div class="logo_container">
  <div class="logo"></div>  
  <nav class="navbar navbar-expand-lg">
    <span class="badge bg-primary" [hidden]="true">WeBaby Game Company</span>
    <div *ngIf="val > 1" class="video-background video-opacity-low-down">
      <video #videoPlayer class="video" [autoplay]="true" [muted]="true" [loop]="false" type="video/mp4" (ended)="onVideoEnd()">
        <source src="./../../assets/video/movie-sprites-3.0.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
  </nav>
</div>

