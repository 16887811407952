
<div class="container">
  <div *ngFor="let game of gamesjson">
    <div class="game-badge" data-aos-duration="3000"  data-aos="fade-up" >
          <div class="image_socket">
            <img [src]=game.image  ngClass="img_fit" />
          </div>
          <div class="game-info">
            <div  data-aos-duration="3000"  data-aos="fade-up" class="gameHeader" >
              {{ game.name }} <br>
                  <span *ngFor="let cat of game.category; let i=index;" class="bg-transparent">{{cat}}
                     <span *ngIf ="i< game.category.length-1" ></span>
                  </span>
            </div>

            <div class="card cr-transparent">
              <div  data-aos-duration="3000"  data-aos="fade-right" class="card-header card-header-font-size">
                <a class="btn btn-outline-warning font-size-header" routerLink="/game">Kup grę</a>
              </div>
              <div data-aos-duration="3000"  data-aos="fade-up"  class="card-body">
                <div data-aos-duration="3000"  data-aos="fade-up" class="card-title">{{game.name}}</div>
                <p class="card-text font-size-header">{{game.description}}</p>
                <a href="#" class="btn btn-primary font-size-header">Go UP</a>
              </div>
            </div>
            <div>
              <button data-aos-duration="3000"  data-aos="fade-left" type="button" class="card glow-on-hover">
                <div [hidden]="true">
                  Gra opublikowana w dniu {{game.createdAt | date:'dd MMM YYYY HH:mm:ss' }} <span class="card card-body glow-on-hover">Od lat: {{game.age}}</span>
                </div>
                <div class="card-text-description"> 
                   Zobacz filmy o grze. Dowiesz się, więcej o rozgrywce, o bohaterach gry i o sposobach na wykonanie niektórych zadań
                </div>
                </button>
            </div>
          </div>
    </div>
    <div class="spaceBetween"></div>
  </div>
</div>
