<div [hidden]="isToDisplay == false" class="sticky" >
  <div hidden>MENU</div>
  <ul>
    <li><div class="awrap"><a href="#">Games</a></div></li>
    <li><div class="awrap"><a href="#">Shop</a></div></li>
    <li><div class="awrap"><a href="#">Contact</a></div></li>
    <!-- <li><a href="#"></a></li>
    <li><a href="#"></a></li>
    <li><a href="#"></a></li>
    <li><a href="#"></a></li>
    <li><a href="#"></a></li>
    <li><a href="#"></a></li>
    <li><a href="#"></a></li>
    <li><a href="#"></a></li>
    <li><a href="#"></a></li>
    <li><a href="#"></a></li>
    <li><a href="#"></a></li> -->
    <li><div class="awrap"><a href="#">Login</a></div></li>
    <li><div class="awrap"><a href="#">Register</a></div></li>
  </ul>
</div>
