
<div *ngIf="showModal" class="modal">
  
    <div class="modal-content">
       <span class="close" (click)="closeModal()">&times;</span>
       <div *ngIf="!language_pl">
 
        <p><h1>Privacy Policy</h1>
          <p>Last updated: April 10, 2024</p>
          <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
          <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the <a href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/" target="_blank">Free Privacy Policy Generator</a>.</p>
          <h2>Interpretation and Definitions</h2>
          <h3>Interpretation</h3>
          <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
          <h3>Definitions</h3>
          <p>For the purposes of this Privacy Policy:</p>
          <ul>
          <li>
          <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
          </li>
          <li>
          <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
          </li>
          <li>
          <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Webaby, ul. Żytnia 13, 01-181 Warszawa.</p>
          </li>
          <li>
          <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
          </li>
          <li>
          <p><strong>Country</strong> refers to:  Poland</p>
          </li>
          <li>
          <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
          </li>
          <li>
          <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
          </li>
          <li>
          <p><strong>Service</strong> refers to the Website.</p>
          </li>
          <li>
          <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
          </li>
          <li>
          <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
          </li>
          <li>
          <p><strong>Website</strong> refers to Webaby, accessible from <a href="https://webaby.io" rel="external nofollow noopener" target="_blank">https://webaby.io</a></p>
          </li>
          <li>
          <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
          </li>
          </ul>
          <h2>Collecting and Using Your Personal Data</h2>
          <h3>Types of Data Collected</h3>
          <h4>Personal Data</h4>
          <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
          <ul>
          <li>
          <p>Email address</p>
          </li>
          <li>
          <p>Usage Data</p>
          </li>
          </ul>
          <h4>Usage Data</h4>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
          <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
          <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
          <h4>Tracking Technologies and Cookies</h4>
          <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
          <ul>
          <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
          <li><strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
          </ul>
          <p>Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. Learn more about cookies on the <a href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking" target="_blank">Free Privacy Policy website</a> article.</p>
          <p>We use both Session and Persistent Cookies for the purposes set out below:</p>
          <ul>
          <li>
          <p><strong>Necessary / Essential Cookies</strong></p>
          <p>Type: Session Cookies</p>
          <p>Administered by: Us</p>
          <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>
          </li>
          <li>
          <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Us</p>
          <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
          </li>
          <li>
          <p><strong>Functionality Cookies</strong></p>
          <p>Type: Persistent Cookies</p>
          <p>Administered by: Us</p>
          <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
          </li>
          </ul>
          <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
          <h3>Use of Your Personal Data</h3>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
          <li>
          <p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
          </li>
          <li>
          <p><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
          </li>
          <li>
          <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
          </li>
          <li>
          <p><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
          </li>
          <li>
          <p><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
          </li>
          <li>
          <p><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</p>
          </li>
          <li>
          <p><strong>For business transfers:</strong> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
          </li>
          <li>
          <p><strong>For other purposes</strong>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
          </li>
          </ul>
          <p>We may share Your personal information in the following situations:</p>
          <ul>
          <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.</li>
          <li><strong>For business transfers:</strong> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
          <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
          <li><strong>With business partners:</strong> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
          <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
          <li><strong>With Your consent</strong>: We may disclose Your personal information for any other purpose with Your consent.</li>
          </ul>
          <h3>Retention of Your Personal Data</h3>
          <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
          <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
          <h3>Transfer of Your Personal Data</h3>
          <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
          <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
          <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
          <h3>Delete Your Personal Data</h3>
          <p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
          <p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
          <p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
          <p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
          <h3>Disclosure of Your Personal Data</h3>
          <h4>Business Transactions</h4>
          <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
          <h4>Law enforcement</h4>
          <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
          <h4>Other legal requirements</h4>
          <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
          <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>Protect the personal safety of Users of the Service or the public</li>
          <li>Protect against legal liability</li>
          </ul>
          <h3>Security of Your Personal Data</h3>
          <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
          <h2>Children's Privacy</h2>
          <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
          <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
          <h2>Links to Other Websites</h2>
          <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
          <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
          <h2>Changes to this Privacy Policy</h2>
          <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
          <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
          <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
          <h2>Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, You can contact us:</p>
          <ul>
          <li>By visiting this page on our website: <a href="https://webaby.io" rel="external nofollow noopener" target="_blank">https://webaby.io</a></li>
          </ul>
      </div >
      <div *ngIf="language_pl">
            <p><h1>Polityka prywatności</h1>
              <p>Ostatnia aktualizacja: 10 kwietnia 2024</p>
              <p>Ta Polityka prywatności opisuje nasze zasady i procedury dotyczące zbierania, wykorzystywania i ujawniania Twoich informacji podczas korzystania z Usługi oraz informuje o Twoich prawach dotyczących prywatności i sposobach, w jakie prawo chroni Twoją prywatność.</p>
              <p>Używamy Twoich danych osobowych w celu świadczenia i ulepszania Usługi. Korzystając z Usługi, zgadzasz się na zbieranie i wykorzystywanie informacji zgodnie z niniejszą Polityką prywatności. Ta Polityka prywatności została stworzona przy pomocy <a href="https://www.freeprivacypolicy.com/free-privacy-policy-generator/" target="_blank">Generatora darmowej polityki prywatności</a>.</p>
              <h2>Interpretacja i definicje</h2>
              <h3>Interpretacja</h3>
              <p>Słowa, których pierwsza litera jest pisana wielką literą, mają znaczenie określone w poniższych warunkach. Następujące definicje mają takie samo znaczenie, niezależnie od tego, czy występują w liczbie pojedynczej, czy mnogiej.</p>
              <h3>Definicje</h3>
              <p>Do celów niniejszej Polityki prywatności:</p>
              <ul>
              <li>
              <p><strong>Konto</strong> oznacza unikalne konto utworzone dla Ciebie w celu uzyskania dostępu do naszej Usługi lub jej części.</p>
              </li>
              <li>
              <p><strong>Podmiot powiązany</strong> oznacza podmiot, który kontroluje, jest kontrolowany przez lub pozostaje pod wspólną kontrolą z daną stroną, gdzie "kontrola" oznacza posiadanie 50% lub więcej udziałów, udziałów kapitałowych lub innych papierów wartościowych uprawnionych do głosowania na wybór dyrektorów lub innych organów zarządzających.</p>
              </li>
              <li>
              <p><strong>Firma</strong> (nazywana również "Firma", "My", "Nas" lub "Nasz" w niniejszej Umowie) odnosi się do Webaby, ul. Żytnia 13, 01-181 Warszawa.</p>
              </li>
              <li>
              <p><strong>Pliki cookie</strong> to małe pliki, które są umieszczane na Twoim komputerze, urządzeniu mobilnym lub innym urządzeniu przez stronę internetową, zawierające szczegóły Twojej historii przeglądania na tej stronie wśród wielu innych zastosowań.</p>
              </li>
              <li>
              <p><strong>Kraj</strong> odnosi się do: Polska</p>
              </li>
              <li>
              <p><strong>Urządzenie</strong> oznacza każde urządzenie, które może uzyskać dostęp do Usługi, takie jak komputer, telefon komórkowy lub tablet cyfrowy.</p>
              </li>
              <li>
              <p><strong>Dane osobowe</strong> oznaczają wszelkie informacje, które odnoszą się do zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej.</p>
              </li>
              <li>
              <p><strong>Usługa</strong> odnosi się do strony internetowej.</p>
              </li>
              <li>
              <p><strong>Dostawca usług</strong> oznacza każdą osobę fizyczną lub prawną, która przetwarza dane w imieniu Firmy. Odnosi się do firm trzecich lub osób fizycznych zatrudnionych przez Firmę w celu ułatwienia świadczenia Usługi, świadczenia Usługi w imieniu Firmy, świadczenia usług związanych z Usługą lub udzielania Firmie pomocy w analizie sposobu wykorzystywania Usługi.</p>
              </li>
              <li>
              <p><strong>Dane o użytkowaniu</strong> odnosi się do danych zbieranych automatycznie, generowanych przez korzystanie z Usługi lub z samej infrastruktury Usługi (na przykład czas trwania wizyty na stronie).</p>
              </li>
              <li>
              <p><strong>Strona internetowa</strong> odnosi się do Webaby, dostępna pod adresem <a href="https://webaby.io" rel="external nofollow noopener" target="_blank">https://webaby.io</a></p>
              </li>
              <li>
              <p><strong>Ty</strong> oznacza osobę fizyczną uzyskującą dostęp do Usługi lub korzystającą z Usługi, albo firmę, lub inny podmiot prawny, w imieniu którego taka osoba uzyskuje dostęp do Usługi lub z niej korzysta, w zależności od przypadku.</p>
              </li>
              </ul>
              <h2>Zbieranie i wykorzystywanie Twoich danych osobowych</h2>
              <h3>Rodzaje zbieranych danych</h3>
              <h4>Dane osobowe</h4>
              <p>Podczas korzystania z naszej Usługi możemy poprosić Cię o podanie nam pewnych informacji umożliwiających identyfikację, które mogą być używane do kontaktu z Tobą lub identyfikacji Ciebie. Informacje umożliwiające identyfikację mogą obejmować, ale nie ograniczają się do:</p>
              <ul>
              <li>
              <p>Adres e-mail</p>
              </li>
              <li>
              <p>Dane o użytkowaniu</p>
              </li>
              </ul>
              <h4>Dane o użytkowaniu</h4>
              <p>Dane o użytkowaniu są zbierane automatycznie podczas korzystania z Usługi.</p>
              <p>Dane o użytkowaniu mogą obejmować takie informacje, jak adres protokołu internetowego Twojego urządzenia (np. adres IP), typ przeglądarki, wersja przeglądarki, strony naszej Usługi, które odwiedzasz, czas i data Twojej wizyty, czas spędzony na tych stronach, unikalne identyfikatory urządzeń i inne dane diagnostyczne.</p>
              <p>Gdy uzyskujesz dostęp do Usługi za pomocą urządzenia mobilnego lub za jego pośrednictwem, możemy automatycznie zbierać pewne informacje, w tym, ale nie wyłącznie, rodzaj używanego urządzenia mobilnego, unikalny identyfikator urządzenia mobilnego, adres IP urządzenia mobilnego, system operacyjny urządzenia mobilnego, rodzaj przeglądarki internetowej używanej na urządzeniu mobilnym, unikalne identyfikatory urządzeń i inne dane diagnostyczne.</p>
              <p>Możemy również zbierać informacje, które Twoja przeglądarka wysyła za każdym razem, gdy odwiedzasz naszą Usługę lub gdy uzyskujesz dostęp do Usługi za pomocą urządzenia mobilnego lub za jego pośrednictwem.</p>
              <h4>Technologie śledzenia i pliki cookie</h4>
              <p>Używamy plików cookie i podobnych technologii śledzenia, aby śledzić aktywność na naszej Usłudze i przechowywać pewne informacje. Technologie śledzenia, które wykorzystujemy, obejmują beacony, tagi i skrypty do zbierania i śledzenia informacji oraz do ulepszania i analizowania naszej Usługi. Technologie, które używamy, mogą obejmować:</p>
              <ul>
              <li><strong>Pliki cookie lub przeglądarkowe pliki cookie.</strong> Cookie to mały plik umieszczony na Twoim urządzeniu. Możesz ustawić swoją przeglądarkę tak, aby odrzucała wszystkie pliki cookie lub aby wskazywała, kiedy plik cookie jest wysyłany. Jednakże, jeśli nie zaakceptujesz plików cookie, możesz nie być w stanie korzystać z niektórych części naszej Usługi. O ile nie dostosujesz ustawień przeglądarki tak, aby odrzucała pliki cookie, nasza Usługa może używać plików cookie.</li>
              <li><strong>Web beacons.</strong> Niektóre sekcje naszej Usługi i nasze e-maile mogą zawierać małe pliki elektroniczne znane jako web beacons (znane również jako clear gifs, pixel tags i single-pixel gifs), które umożliwiają Firmie, na przykład, liczenie użytkowników, którzy odwiedzili te strony lub otworzyli e-mail oraz inne powiązane statystyki dotyczące strony internetowej (na przykład, rejestrowanie popularności danej sekcji i weryfikacja integralności systemu i serwera).</li>
              </ul>
              <p>Pliki cookie mogą być "stałe" lub "sesyjne". Stałe pliki cookie pozostają na Twoim komputerze osobistym lub urządzeniu mobilnym, gdy przechodzisz w tryb offline, natomiast sesyjne pliki cookie są usuwane natychmiast po zamknięciu przeglądarki. Dowiedz się więcej o plikach cookie na stronie <a href="https://www.freeprivacypolicy.com/blog/sample-privacy-policy-template/#Use_Of_Cookies_And_Tracking" target="_blank">stronie internetowej Free Privacy Policy</a>.</p>
              <p>Używamy zarówno sesyjnych, jak i stałych plików cookie do celów określonych poniżej:</p>
              <ul>
              <li>
              <p><strong>Niezbędne / niezbędne pliki cookie</strong></p>
              <p>Rodzaj: Sesyjne pliki cookie</p>
              <p>Administrowane przez: Nas</p>
              <p>Cel: Te pliki cookie są niezbędne, aby świadczyć Ci usługi dostępne za pośrednictwem Strony internetowej i umożliwiać korzystanie z niektórych jej funkcji. Pomagają one w uwierzytelnianiu użytkowników i zapobiegają oszustwom związanym z kontami użytkowników. Bez tych plików cookie usługi, o które prosiłeś, nie mogą być świadczone, a my używamy tych plików cookie wyłącznie do świadczenia Ci tych usług.</p>
              </li>
              <li>
              <p><strong>Polityka plików cookie / Akceptacja powiadomienia o plikach cookie</strong></p>
              <p>Rodzaj: Stałe pliki cookie</p>
              <p>Administrowane przez: Nas</p>
              <p>Cel: Te pliki cookie identyfikują, czy użytkownicy zaakceptowali użycie plików cookie na Stronie internetowej.</p>
              </li>
              <li>
              <p><strong>Pliki cookie dotyczące funkcjonalności</strong></p>
              <p>Rodzaj: Stałe pliki cookie</p>
              <p>Administrowane przez: Nas</p>
              <p>Cel: Te pliki cookie umożliwiają nam zapamiętywanie wyborów, których dokonujesz podczas korzystania ze Strony internetowej, takich jak zapamiętywanie danych logowania lub preferencji językowych. Celem tych plików cookie jest zapewnienie Ci bardziej osobistego doświadczenia i uniknięcie konieczności ponownego wprowadzania preferencji za każdym razem, gdy korzystasz ze Strony internetowej.</p>
              </li>
              </ul>
              <p>Aby uzyskać więcej informacji na temat plików cookie, których używamy i Twoich wyborów dotyczących plików cookie, odwiedź naszą Politykę plików cookie lub sekcję Pliki cookie w naszej Polityce prywatności.</p>
              <h3>Wykorzystywanie Twoich danych osobowych</h3>
              <p>Firma może wykorzystywać dane osobowe do następujących celów:</p>
              <ul>
              <li>
              <p><strong>Świadczenie i utrzymanie naszej Usługi</strong>, w tym monitorowanie korzystania z naszej Usługi.</p>
              </li>
              <li>
              <p><strong>Zarządzanie Twoim kontem:</strong> zarządzanie Twoją rejestracją jako użytkownika Usługi. Dane osobowe, które podajesz, mogą dać Ci dostęp do różnych funkcji Usługi, które są dostępne dla Ciebie jako zarejestrowanego użytkownika.</p>
              </li>
              <li>
              <p><strong>Wykonanie umowy:</strong> rozwój, zgodność i realizacja umowy zakupu produktów, przedmiotów lub usług, które zakupiłeś lub jakiejkolwiek innej umowy z nami za pośrednictwem Usługi.</p>
              </li>
              <li>
              <p><strong>Kontakt z Tobą:</strong> Kontakt z Tobą za pomocą poczty elektronicznej, połączeń telefonicznych, SMS-ów lub innych równoważnych form komunikacji elektronicznej, takich jak powiadomienia push aplikacji mobilnej dotyczących aktualizacji lub komunikatów informacyjnych związanych z funkcjonalnościami, produktami lub usługami zakontraktowanymi, w tym aktualizacjami zabezpieczeń, gdy jest to konieczne lub uzasadnione do ich wdrożenia.</p>
              </li>
              <li>
              <p><strong>Przekazywanie Ci</strong> wiadomości, ofert specjalnych i ogólnych informacji na temat innych towarów, usług i wydarzeń, które oferujemy, a które są podobne do tych, które już zakupiłeś lub o które zapytałeś, chyba że zrezygnowałeś z otrzymywania takich informacji.</p>
              </li>
              <li>
              <p><strong>Zarządzanie Twoimi prośbami:</strong> Aby obsługiwać i zarządzać Twoimi prośbami skierowanymi do Nas.</p>
              </li>
              <li>
              <p><strong>W przypadku transferów biznesowych:</strong> Możemy wykorzystywać Twoje informacje do oceny lub przeprowadzenia fuzji, zbycia, restrukturyzacji, reorganizacji, rozwiązania lub innej sprzedaży lub transferu całości lub części naszych aktywów, zarówno w ramach funkcjonującej firmy, jak i w ramach upadłości, likwidacji lub podobnego postępowania, w którym dane osobowe przechowywane przez nas dotyczące użytkowników naszej Usługi są jednym z przenoszonych aktywów.</p>
              </li>
              <li>
              <p><strong>Do innych celów:</strong> Możemy wykorzystywać Twoje informacje do innych celów, takich jak analiza danych, identyfikacja trendów użytkowania, ocena skuteczności naszych kampanii promocyjnych oraz ocena i poprawa naszej Usługi, produktów, usług, marketingu i Twojego doświadczenia.</p>
              </li>
              </ul>
              <p>Możemy udostępniać Twoje dane osobowe w następujących sytuacjach:</p>
              <ul>
              <li><strong>Współpracownikom:</strong> Możemy udostępniać Twoje dane osobowe współpracownikom w celu monitorowania i analizowania korzystania z naszej Usługi, aby się z Tobą skontaktować.</li>
              <li><strong>W przypadku transferów biznesowych:</strong> Możemy udostępniać lub przekazywać Twoje dane osobowe w związku z, lub podczas negocjacji, fuzji, sprzedaży aktywów Firmy, finansowania lub przejęcia całości lub części naszej działalności przez inną firmę.</li>
              <li><strong>Podmiotom powiązanym:</strong> Możemy udostępniać Twoje informacje naszym podmiotom powiązanym, w takim przypadku będziemy wymagać od tych podmiotów powiązanych przestrzegania niniejszej Polityki prywatności. Podmioty powiązane obejmują naszą spółkę macierzystą oraz wszelkie inne spółki zależne, partnerów joint venture lub inne firmy, które kontrolujemy lub które są pod wspólną kontrolą z nami.</li>
              <li><strong>Partnerom biznesowym:</strong> Możemy udostępniać Twoje informacje naszym partnerom biznesowym w celu oferowania Ci określonych produktów, usług lub promocji.</li>
              <li><strong>Innym użytkownikom:</strong> Gdy udostępniasz dane osobowe lub w inny sposób wchodzisz w interakcje w publicznych obszarach z innymi użytkownikami, takie informacje mogą być przeglądane przez wszystkich użytkowników i mogą być publicznie rozpowszechniane na zewnątrz.</li>
              <li><strong>Za Twoją zgodą</strong>: Możemy ujawniać Twoje dane osobowe w dowolnym innym celu za Twoją zgodą.</li>
              </ul>
              <h3>Przechowywanie Twoich danych osobowych</h3>
              <p>Firma będzie przechowywać Twoje dane osobowe tylko przez okres niezbędny do realizacji celów określonych w niniejszej Polityce prywatności. Będziemy przechowywać i wykorzystywać Twoje dane osobowe w zakresie niezbędnym do spełnienia naszych obowiązków prawnych (na przykład, jeśli będziemy zobowiązani do zachowania Twoich danych w celu spełnienia obowiązujących przepisów prawa), rozstrzygania sporów i egzekwowania naszych umów prawnych i zasad.</p>
              <p>Firma będzie również przechowywać dane o użytkowaniu do celów analizy wewnętrznej. Dane o użytkowaniu są zazwyczaj przechowywane przez krótszy okres czasu, z wyjątkiem przypadków, gdy te dane są wykorzystywane do wzmocnienia bezpieczeństwa lub poprawy funkcjonalności naszej Usługi, albo jesteśmy prawnie zobowiązani do przechowywania tych danych przez dłuższy okres czasu.</p>
              <h3>Transfer Twoich danych osobowych</h3>
              <p>Twoje informacje, w tym dane osobowe, są przetwarzane w biurach operacyjnych Firmy oraz w innych miejscach, w których znajdują się strony zaangażowane w przetwarzanie. Oznacza to, że te informacje mogą być przesyłane do — i przechowywane na — komputerach znajdujących się poza Twoim stanem, prowincją, krajem lub inną jurysdykcją rządową, gdzie przepisy o ochronie danych mogą się różnić od przepisów Twojej jurysdykcji.</p>
              <p>Twoja zgoda na niniejszą Politykę prywatności, a następnie przekazanie takich informacji oznacza Twoją zgodę na ten transfer.</p>
              <p>Firma podejmie wszelkie kroki uzasadnione, aby zapewnić, że Twoje dane są traktowane bezpiecznie i zgodnie z niniejszą Polityką prywatności i żaden transfer Twoich danych osobowych nie będzie miał miejsca do organizacji lub kraju, chyba że są wprowadzone odpowiednie kontrole, w tym bezpieczeństwo Twoich danych i innych danych osobowych.</p>
              <h3>Usuwanie Twoich danych osobowych</h3>
              <p>Masz prawo do usunięcia lub żądania naszej pomocy w usunięciu danych osobowych, które zebraliśmy o Tobie.</p>
              <p>Nasza Usługa może dać Ci możliwość usunięcia pewnych informacji o Tobie w ramach Usługi.</p>
              <p>Możesz aktualizować, zmieniać lub usuwać swoje informacje w dowolnym momencie, logując się na swoje konto, jeśli je posiadasz, i odwiedzając sekcję ustawień konta, która pozwala zarządzać swoimi danymi osobowymi. Możesz również skontaktować się z nami, aby zażądać dostępu, poprawienia lub usunięcia wszelkich danych osobowych, które nam przekazałeś.</p>
              <p>Należy jednak pamiętać, że możemy być zobowiązani do zachowania pewnych informacji, gdy mamy prawny obowiązek lub uzasadnioną podstawę do ich zachowania.</p>
              <h3>Ujawnianie Twoich danych osobowych</h3>
              <h4>Transakcje biznesowe</h4>
              <p>Jeśli Firma jest zaangażowana w fuzję, przejęcie lub sprzedaż aktywów, Twoje dane osobowe mogą być przekazane. Przed przekazaniem Twoich danych osobowych i poddaniem ich innej polityce prywatności, poinformujemy Cię o tym.</p>
              <h4>Organom ścigania</h4>
              <p>W określonych okolicznościach Firma może być zobowiązana do ujawnienia Twoich danych osobowych, jeśli jest to wymagane przez prawo lub w odpowiedzi na ważne wnioski organów publicznych (np. sąd lub agencja rządowa).</p>
              <h4>Inne wymagania prawne</h4>
              <p>Firma może ujawniać Twoje dane osobowe w dobrej wierze, że takie działanie jest konieczne do:</p>
              <ul>
              <li>Spełnienia obowiązku prawnego</li>
              <li>Ochrony i obrony praw lub własności Firmy</li>
              <li>Zapobiegania lub badania możliwego niewłaściwego postępowania w związku z Usługą</li>
              <li>Ochrony osobistego bezpieczeństwa użytkowników Usługi lub społeczeństwa</li>
              <li>Ochrony przed odpowiedzialnością prawną</li>
              </ul>
              <h3>Bezpieczeństwo Twoich danych osobowych</h3>
              <p>Bezpieczeństwo Twoich danych osobowych jest dla nas ważne, ale pamiętaj, że żadna metoda transmisji przez Internet, ani metoda przechowywania elektronicznego nie jest w 100% bezpieczna. Podczas gdy staramy się używać komercyjnie akceptowalnych środków do ochrony Twoich danych osobowych, nie możemy zagwarantować ich absolutnego bezpieczeństwa.</p>
              <h2>Prywatność dzieci</h2>
              <p>Nasza Usługa nie jest skierowana do osób poniżej 13 roku życia. Nie zbieramy świadomie informacji umożliwiających identyfikację od nikogo poniżej 13 roku życia. Jeśli jesteś rodzicem lub opiekunem i jesteś świadomy, że Twoje dziecko przekazało nam dane osobowe, skontaktuj się z nami. Jeśli staniemy się świadomi, że zebraliśmy dane osobowe od kogokolwiek poniżej 13 roku życia bez weryfikacji zgody rodziców, podejmiemy kroki w celu usunięcia tych informacji z naszych serwerów.</p>
              <p>Jeśli musimy polegać na zgodzie jako podstawie prawnej do przetwarzania Twoich informacji, a Twój kraj wymaga zgody rodzica, możemy wymagać zgody Twojego rodzica przed zebraniem i użyciem tych informacji.</p>
              <h2>Linki do innych stron internetowych</h2>
              <p>Nasza Usługa może zawierać linki do innych stron internetowych, które nie są przez nas obsługiwane. Jeśli klikniesz link do strony trzeciej, zostaniesz przekierowany na stronę tej strony trzeciej. Zdecydowanie zalecamy zapoznanie się z Polityką prywatności każdej odwiedzanej strony.</p>
              <p>Nie mamy żadnej kontroli i nie ponosimy żadnej odpowiedzialności za treść, polityki prywatności lub praktyki stron trzecich lub usług.</p>
              <h2>Zmiany w tej Polityce prywatności</h2>
              <p>Od czasu do czasu możemy aktualizować naszą Politykę prywatności. Powiadomimy Cię o wszelkich zmianach, publikując nową Politykę prywatności na tej stronie.</p>
              <p>Poinformujemy Cię za pomocą wiadomości e-mail i/lub wyraźnego powiadomienia na naszej Usłudze, zanim zmiana wejdzie w życie, oraz zaktualizujemy datę "Ostatnia aktualizacja" na górze niniejszej Polityki prywatności.</p>
              <p>Zalecamy okresowe przeglądanie niniejszej Polityki prywatności pod kątem wszelkich zmian. Zmiany w niniejszej Polityce prywatności są skuteczne, gdy zostaną opublikowane na tej stronie.</p>
              <h2>Kontakt z nami</h2>
              <p>Jeśli masz jakiekolwiek pytania dotyczące niniejszej Polityki prywatności, możesz skontaktować się z nami:</p>
              <ul>
              <li>Odwiedzając tę stronę na naszej stronie internetowej: <a href="https://webaby.io" rel="external nofollow noopener" target="_blank">https://webaby.io</a></li>
              </ul>
          </div>
  
</div>
</div>